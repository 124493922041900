import React, { useState, useEffect, useContext } from 'react';

import Section from '@latitude/section';
import { List, ListItem } from '@latitude/list';
import { Text } from '@latitude/text';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/calculators-and-tools/personal-loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import {
  PL_APPLY_CTA_TRACK_ID,
  PL_SYMPLE_MINIMUM_AMOUNT,
  PL_SYMPLE_DEFAULT_PERIOD,
  BREAKPOINT
} from '@/utils/constants';
import {
  redirectUrl,
  softQuoteHrefFunc
} from '@/components/EstimateRateWidget/utils';

import heroImage from '../../images/hero/pl-loan-repayment.webp';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { AnalyticsContext } from 'latitude-analytics';
import CardCollection from '@/components/lab-components/CardCollection';

const COLOUR_PALE_BLUE = '#E5F6FF';
const CTA_LABEL = 'Get your personalised rate';

  const featureSliderJson = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PageData.content.whyChooseLatitude
    }
  ];


const WHY_LATITUDE_ITEMS = [
  {
    icon: './images/why-latitude2.webp',
    title: 'Award-winning Personal Loans',
    jsx: (
      <Text color={COLOR.WHITE}>
        WeMoney Best in Flexibility 2 years running.
      </Text>
    )
  }
];

// eslint-disable-next-line arrow-body-style
const PLCalculatorPage = ({ location }) => {
  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();
  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    sessionStorage.setItem('loanAmount', PL_SYMPLE_MINIMUM_AMOUNT.toString());
    sessionStorage.setItem('repaymentPeriod', PL_SYMPLE_DEFAULT_PERIOD);
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'personal-loan-repayment-calculator',
    page_pageType: 'calculator-page',
    page_siteSection: 'loans',
    page_siteSubsection: 'personal-loan-repayment-calculator',
    product_productid: ["PLAULF-WEB"]
  };

  return (
    <Layout
      location={location}
      title={PageData.title}
      metaDesc={PageData.metaDesc}
      canonical="/personal-loan-repayment-calculator/"
    >
      <main className="navigation-spacer"
        css={`
            overflow-x: hidden;
            `}
      >
        <MobileAppInstallPrompts />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title={
              <>
                Personal Loan
                <br />
                Repayment Calculator
              </>
            }
            subTitle="How much will a Latitude Personal Loan actually cost you? Use our calculator now to explore potential repayments."
          />
        )}
        <div className="d-none d-lg-block" css="position:relative; z-index:2;">
          <StickyNavigationBranded
            items={PageData.nav}
            phoneNumber={PageData.content.phoneNumber}
            ctaHref={softQuoteHrefFunc(softQuoteHref)}
            ctaText={CTA_LABEL}
            offsetElem="[data-sticky-navigation-offset]"
            target="_self"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              label: CTA_LABEL,
              location: 'personal-loan-calculator-nav'
            }}
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <Section id="calculator" css={{ backgroundColor: COLOUR_PALE_BLUE }}>
          <PersonalLoanCalculator
            loans1
            applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
          />
        </Section>
        <FeaturesSlider
            id="why-latitude"
            css={`
              background-color: #E6E6E6;
              && ul {
                text-align: left;
                @media (max-width: ${BREAKPOINT.MD}) {
                  padding: 20px;
                }
              }
              && li {
                @media (max-width: ${BREAKPOINT.MD}) {
                  text-align: center;
                }
              }
              && h4 {
                text-align: left;
                font-size: 32px;
                line-height: 36px;
                margin: 0px;
                @media (max-width: ${BREAKPOINT.MD}) {
                  text-align: center;
                }
              }
              &&.lfs-features-slider {
                padding: 32px 0px;
              }
              && h4 > p {
                padding: 0px;
                margin: 0px;
              }
              && div.lfs-card-icon {
                padding: 0;
                width: 120px !important;
                height: 120px !important;
                @media (max-width: ${BREAKPOINT.MD}) {
                  margin-bottom: 0px;
                }
              }
              && svg {
                border-radius: 50%;
                background-color: white;
                padding: 0px 23px;
              }
              && .lfs-card-text {
                @media (max-width: ${BREAKPOINT.MD}) {
                    margin-top: 0px;
                }
              }
              && .lfs-card {
                @media (max-width: ${BREAKPOINT.MD}) {
                  gap: 16px;
                }
              }
            `}
            key={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .featureCards
            }
          />
          <PromoBanner
            {...(state?.promoBannerData?.[0] ||
              PageData?.contentful?.promoBannerData?.[0])}
          />

        
        <CardCollection
            id="applying-is-simple"
            data={
              state?.cardCollectionData?.[0] ||
              PageData?.contentful?.cardCollectionData?.[0]
            }
          />

        <PromoBanner id="eligibility-criteria" {...state?.promoBannerData?.[1] || PageData?.contentful?.promoBannerData?.[1]} />

        <CardCollection
            id="explore-loans"
            data={
              state?.cardCollectionData?.[1] ||
              PageData?.contentful?.cardCollectionData?.[1]
            }
          />


        <ImportantInformation
          data={require('@/data/json/disclaimer/personal-loan1.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
          css={`
            .important-info svg {
              stroke-width: unset;
            }
            .important-info__header > h3 {
              @media (min-width: 992px) {
                margin: 0 0 0 6px;
              }
            }
          `}
        />
      </main>
    </Layout>
  );
};

export default PLCalculatorPage;
